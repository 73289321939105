<template>
  <form @submit.prevent="updatePlan">
    <modal-card :title="title">
      <loading v-if="loading" />
      <div v-else>
        <b-message v-if="!upgrade" type="is-warning">
          <p>
            <strong>Important!</strong> Changing a plan only takes effect when
            the subscription NEXT renews. If you wish to upgrade a plan with
            immediate effect, please use the "Upgrade" functionality instead.
          </p>
        </b-message>
        <b-table
          ref="table"
          :data="sortedTerms"
          :mobile-cards="true"
          :detailed="true"
          :show-detail-icon="true"
          hoverable
          striped
          detail-key="_termId"
          class="has-vertical-align-middle"
          @click="selectPlan"
        >
          <template slot-scope="props">
            <b-table-column label width="1">
              <b-radio
                :value="selected.termId"
                :native-value="props.row._termId"
                :disabled="processing"
                @input="selectPlan(props.row)"
              />
            </b-table-column>

            <b-table-column field="name" label="Name" width="300">
              <span>{{ props.row.name }}</span>
              <status-label
                v-if="!props.row.isActive"
                class="has-margin-left-50"
                :status="'discontinued'"
              />
            </b-table-column>

            <b-table-column label="Total monthly">
              <strong v-if="props.row.termLength === 0">{{
                total(props.row)
              }}</strong>
              <strong v-else>{{ monthlyTotal(props.row) }}</strong>
            </b-table-column>

            <b-table-column label="Total">
              <strong>{{ total(props.row) }}</strong>
            </b-table-column>
          </template>

          <template slot="detail" slot-scope="props">
            <p>{{ props.row.description }}</p>
          </template>
        </b-table>
      </div>

      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="loading || processing || !isValid || !hasChange"
        type="submit"
        class="button is-success"
      >
        {{ upgrade ? "Continue" : "Change plan" }}
      </button>
    </modal-card>
  </form>
</template>

<script>
import { collection, getDocs, query, where } from "@firebase/firestore";
export default {
  name: "SelectPlanModal",
  props: {
    siteId: {
      type: String,
      required: true
    },
    contractId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      products: ["WM9jpuPoqJ6HoigHuq1b"],
      productTerms: {},
      selected: {
        productId: null,
        termId: null
      },
      processing: false
    };
  },
  computed: {
    upgrade() {
      return !this.site.isSubscribed;
    },
    currency() {
      return this.$store.getters["user/currency"]();
    },
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    contract() {
      return (
        this.$store.getters["billing/contract"](
          this.contractId || this.siteId
        ) || {}
      );
    },
    sortedTerms() {
      return this.$_(this.productTerms)
        .orderBy(
          ["isActive", "_productId", "termLength"],
          ["desc", "desc", "desc"]
        )
        .value();
    },
    isValid() {
      if (this.$_.isEmpty(this.selected)) return false;
      if (this.$_.isEmpty(this.selected.termId)) return false;
      if (this.$_.isEmpty(this.selected.productId)) return false;
      if (this.upgrade && this.$_.isEmpty(this.site)) return false;
      return true;
    },
    hasChange() {
      if (this.upgrade) {
        return !this.$_.isEmpty(
          this.$_.get(this.selected, "productId", null) &&
            this.$_.get(this.selected, "termId", null)
        );
      } else {
        return (
          this.$_.get(this.selected, "termId", null) !==
            this.contract.product.term.ref.id ||
          this.$_.get(this.selected, "productId", null) !==
            this.contract.product.term.ref.parent.parent.id
        );
      }
    },
    title() {
      return `${this.upgrade ? "Select" : "Change"} plan for ${this.$_.get(
        this.site,
        "fqdn",
        ""
      )}`;
    }
  },
  watch: {
    contract(newVal) {
      if (newVal) {
        this.selected.termId = this.$_.get(newVal, "product.term.ref.id", null);
        this.selected.productId = this.$_.get(
          newVal,
          "product.term.ref.parent.parent.id",
          null
        );
      }
    }
  },
  created() {
    this.$store.dispatch("billing/observeContract", {
      siteId: this.siteId
    });
    this.$store.dispatch("sites/observeSite", { siteId: this.siteId });

    this.getProducts();

    this.selected.termId = this.$_.get(
      this.contract,
      "product.term.ref.id",
      null
    );
    this.selected.productId = this.$_.get(
      this.contract,
      "product.term.ref.parent.parent.id",
      null
    );
  },
  beforeDestroy() {
    this.$store.dispatch("billing/unobserveContract", {
      siteId: this.siteId
    });
    this.$store.dispatch("sites/unobserveSite", { siteId: this.siteId });
  },
  methods: {
    selectPlan(plan) {
      if (this.processing) return;
      this.selected.productId = plan._productId;
      this.selected.termId = plan._termId;
    },
    openDetail(plan) {
      this.$refs.table.toggleDetails(plan);
    },
    total(term) {
      const termPrice = term.termPrice[this.currency] || 0;
      return this.$formatCurrency(termPrice, this.currency);
    },
    monthlyTotal(term) {
      const monthlyTermPrice =
        (term.termPrice[this.currency] || 0) / (term.termLength || 1);
      return this.$formatCurrency(monthlyTermPrice, this.currency);
    },
    getProducts() {
      const productsRef = query(
        collection(this.$firestore, `products`),
        where(`categories`, `array-contains`, `core`),
        where("type", "==", "subscription")
      );
      getDocs(productsRef).then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(async product => {
            await this.getProductTerms(product);
          });
        }
        this.loading = false;
      });
    },
    getProductTerms(product) {
      const termsRef = collection(product.ref, `terms`);
      return getDocs(termsRef).then(snapshot => {
        if (!snapshot.empty) {
          this.$set(
            this.products,
            product.id,
            this.$_.merge({}, product.data(), {
              _productId: product.id
            })
          );
          snapshot.forEach(term => {
            this.$set(
              this.productTerms,
              term.id,
              this.$_.merge({}, term.data(), {
                _termId: term.id,
                _productId: product.id
              })
            );
          });
        }
      });
    },
    updatePlan() {
      if (!this.isValid) return;
      if (this.upgrade) return this.checkout();
      this.processing = true;
      this.$store
        .dispatch("billing/changeContractPlan", {
          contractId: this.contractId || this.siteId,
          productId: this.selected.productId,
          termId: this.selected.termId
        })
        .then(() => {
          this.processing = false;
          this.$toast.open({
            message: "Plan updated"
          });
          this.$emit("close");
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: `Error updating plan`,
            type: "is-danger"
          });
        });
    },
    checkout() {
      if (!this.isValid) return;
      this.$emit("close");
      this.$modal.open({
        component: () => import("@shared/cart/_orderModal"),
        parent: this.$parent.$parent,
        width: 640,
        hasModalCard: true,
        canCancel: [],
        props: {
          siteId: this.siteId,
          userId: this.site.authorId,
          productId: this.selected.productId,
          productTermId: this.selected.termId
        }
      });
    }
  }
};
</script>
